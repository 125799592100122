<template>
	<div v-if="user.id">
		<v-container class="main-container" v-if="eventError">
			<v-alert text class="w-100 text-left" color="warning">
				{{ eventError }}
			</v-alert>
		</v-container>

		<v-container
			class="main-container flex-center flex-column"
			v-if="event">
			<div class="w-100 d-flex align-center justify-space-between">
				<h2 class="white--text font-weight-light">
					{{ event.name }} RSVP's
				</h2>
				<download-excel :data="event.invitations">
					<v-icon dark>mdi-download</v-icon>
				</download-excel>
			</div>
			<v-divider class="w-100 my-3" dark></v-divider>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				filled
				dense
				dark
				class="w-100 mt-5"
				hide-details></v-text-field>
			<v-data-table
				class="w-100"
				:items="event.invitations"
				item-value="key"
				item-title="name"
				:headers="tableHeaders"
				dark>
				<template v-slot:[`item.status`]="{item}">
					<span style="color: #71a656" v-if="item.status === 1">
						Accepted
					</span>
					<span style="color: #cc564e" v-if="item.status === 0">
						Declined
					</span>
				</template>
				<template v-slot:[`item.created_at`]="{item}">
					{{ formatDate(item.created_at) }}
				</template>
			</v-data-table>
		</v-container>
	</div>
</template>
<script>
export default {
	name: "EventView",
	data: () => ({
		event: null,
		eventError: null,
		search: null,
		tableHeaders: [
			{
				text: "Full Name",
				align: "start",
				sortable: false,
				value: "full_name",
			},
			{
				text: "E-Mail",
				align: "start",
				sortable: false,
				value: "email",
			},
			{
				text: "Phone",
				align: "start",
				sortable: false,
				value: "phone",
			},
			{
				text: "Response",
				align: "start",
				sortable: false,
				value: "status",
			},
			{
				text: "Response Date",
				align: "end",
				sortable: false,
				value: "created_at",
			},
		],
	}),
	mounted() {
		this.init()
		this.$store.dispatch("getUser")
	},
	computed: {
		user() {
			return this.$store.getters.currentUser
		},
	},
	methods: {
		init() {
			apimta
				.get(`/events/id/${this.$route.params.id}`)
				.then((response) => {
					if (response.status === 200 && response.data) {
						this.event = response.data
					} else {
						this.eventError = `Event you are looking for could not be found.`
					}
				})
				.catch((err) => {
					this.eventError = `Something went wrong reaching the event. ${err}`
				})
		},
		formatDate(datetime) {
			const date = new Date(datetime)
			return (
				date.toLocaleDateString("en-US", {
					weekday: "long",
					year: "numeric",
					month: "long",
					day: "numeric",
				}) +
				" at " +
				date.toLocaleTimeString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				})
			)
		},
	},
}
</script>
