import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"
import PageNotFound from "../views/PageNotFound.vue"
import LoginView from "../views/LoginView.vue"
import EventView from "../views/EventView.vue"

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView,
	},
	{
		path: "/events/:id",
		name: "event",
		component: EventView,
	},
	{
		path: "/login",
		name: "login",
		component: LoginView,
	},
	{path: "*", component: PageNotFound},
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	return next()
})

export default router
