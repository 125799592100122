<template>
	<div id="app">
		<v-app>
			<v-main>
				<v-app-bar dark fixed color="#000000" flat class="px-0">
					<v-container
						class="main-container d-flex align-center justify-space-between w-100">
						<div
							class="flex-center pointer"
							@click="$router.push('/')">
							<img
								style="height: 30px"
								src="@/assets/logo.svg"
								alt="MTA"
								class="mr-5" />
							<span
								v-if="user.id"
								class="white--text font-weight-light mt-1">
								Event Manager
							</span>
						</div>
						<v-spacer></v-spacer>

						<v-btn
							v-if="user.id"
							icon
							style="margin-right: -13px"
							@click.stop="drawer = !drawer">
							<v-icon>mdi-menu</v-icon>
						</v-btn>
					</v-container>
				</v-app-bar>
				<v-navigation-drawer
					v-model="drawer"
					fixed
					temporary
					dark
					style="background-color: #000000">
					<div class="w-100 pa-4 d-flex align-center justify-start">
						<span class="white--text" style="opacity: 0.7">
							MENU
						</span>
					</div>

					<v-divider></v-divider>

					<v-list density="compact" nav class="pa-0">
						<v-list-item class="pa-0">
							<v-btn
								class="w-100 text-left"
								plain
								color="error"
								tile
								depressed
								@click="logout()">
								<span class="red--text w-100 text-left">
									Log Out
								</span>
							</v-btn>
						</v-list-item>
					</v-list>
				</v-navigation-drawer>
				<router-view />
				<div
					class="w-100 footer pt-1"
					style="background-color: #000000">
					<v-container class="main-container">
						<span
							class="w-100 text-left overline"
							style="display: flex">
							&copy; M.T. Abraham Group
						</span>
					</v-container>
				</div>
			</v-main>
		</v-app>
	</div>
</template>

<script>
export default {
	name: "App",
	data: () => ({
		drawer: false,
	}),
	mounted() {
		this.$store.dispatch("getUser")
	},
	computed: {
		user() {
			return this.$store.getters.currentUser
		},
	},
	methods: {
		logout() {
			this.$cookies.remove("mta-token")
			window.location.reload()
		},
	},
}
</script>

<style lang="scss">
body {
	padding: 0;
	margin: 0;
}
.theme--dark.v-data-table
	> .v-data-table__wrapper
	> table
	> tbody
	> tr:hover:not(.v-data-table__expanded__content):not(
		.v-data-table__empty-wrapper
	) {
	background: #2c2c2c !important;
	cursor: pointer;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #ffffffde;
	background-color: #161616;
	min-height: 100vh;
	padding-top: 50px;
	position: relative;
}
.footer {
	position: absolute;
	bottom: 0;
}
.v-toolbar__content {
	padding: 10px 0px !important;
}
.main-container {
	max-width: 1400px;
	padding: 0 30px;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.new-menu {
	.v-input {
		min-width: 300px;
		width: 100% !important;
	}
}

.v-text-field__details {
	display: none !important;
}
.w-100 {
	width: 100% !important;
}
.flex-column {
	flex-direction: column;
}
nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
.v-select__slot {
	.v-label {
		font-weight: 200 !important;
	}
}
.v-data-table-header-mobile__wrapper {
	padding-bottom: 15px;
}
.pointer {
	cursor: pointer;
}
@media (max-width: 768px) {
	#app {
		padding-top: 40px;
	}
	.main-container {
		padding: 0 20px;
	}
}
</style>
