import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import DatetimePicker from "vuetify-datetime-picker"
import {api} from "./.env.js"
import store from "./store.js"
import axios from "axios"
import JsonExcel from "vue-json-excel"

Vue.component("downloadExcel", JsonExcel)

const VueCookie = require("vue-cookies")
Vue.use(VueCookie)

Vue.use(DatetimePicker)
Vue.config.productionTip = false

window.apimta = axios.create({
	baseURL: api + "/api",
})

apimta.interceptors.request.use(
	function (config) {
		config.headers["Accept"] = "application/json"
		config.headers["Authorization"] = "Bearer " + VueCookie.get("mta-token")

		return config
	},
	function (error) {
		return Promise.reject(error)
	}
)

new Vue({
	router,
	vuetify,
	store: store,
	created() {
		this.isGuest = this.$cookies.get("mta-token") == null

		let self = this

		apimta.interceptors.response.use(
			(response) => {
				return response
			},
			(error) => {
				if (error.response.status === 401) {
					self.isGuest = true
					self.$cookies.remove("mta-token")
				} else if (error.response.status === 404) {
					self.$router.replace("/404")
				}
			}
		)
	},
	render: (h) => h(App),
}).$mount("#app")
