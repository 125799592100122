<template>
	<div class="home w-100" v-if="user.id">
		<v-container class="main-container w-100">
			<div class="w-100 d-flex align-center justify-space-between">
				<h2 class="white--text font-weight-light">Events</h2>
				<v-menu v-model="menu" offset-y tile :close-on-content-click="false" max-width="100%">
					<template v-slot:activator="{ on, attrs }">
						<v-btn small depressed dark v-bind="attrs" v-on="on" plain>
							<v-icon size="20" class="mr-2">mdi-plus</v-icon>
							New Event
						</v-btn>
					</template>
					<v-card dark class="mt-2">
						<v-card-title>
							<span>Create new event</span>
						</v-card-title>
						<v-divider class="w-100"></v-divider>
						<v-card-text class="pt-2 pb-3 flex-center flex-column new-menu">
							<v-text-field class="w-100 mb-2" dark tile hide-details v-model="newEvent.name"
								label="Event Name"></v-text-field>
							<v-datetime-picker hide-details dark label="Date & time" v-model="newEvent.datetime">
								<template slot="dateIcon">
									<v-icon>mdi-calendar</v-icon>
								</template>
								<template slot="timeIcon">
									<v-icon>mdi-clock-outline</v-icon>
								</template>
							</v-datetime-picker>
							<v-select class="w-100 mt-2 mb-8" dark tile hide-details v-model="newEvent.host"
								label="Host" :items="hosts" item-text="name" item-value="value">
							</v-select>
							<span class="green--text w-100 text-left" v-if="eventSuccess">
								{{ eventSuccess }}
							</span>
							<span class="red--text w-100 text-left" v-if="eventError">
								{{ eventError }}
							</span>
						</v-card-text>
						<v-divider class="w-100"></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn plain small depressed tile @click="menu = false">
								Close
							</v-btn>
							<v-btn plain small depressed tile color="success" :disabled="!newEvent.name ||
		!newEvent.datetime ||
		!newEvent.host
		" @click="saveEvent()">
								Save
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>
			</div>

			<v-alert text class="w-100 text-left" color="error" tile dismissible v-if="eventsErr">
				{{ eventsErr }}
			</v-alert>
			<v-data-table class="mt-4" :items="events" item-value="key" item-title="name" :headers="eventHeaders" dark
				@click:row="eventClick">
				<template v-slot:[`item.datetime`]="{ item }">
					{{ formatDate(item.datetime) }}
				</template>
				<template v-slot:[`item.host`]="{ item }">
					<span style="text-transform: uppercase">
						{{ item.host }}
					</span>
				</template>
				<template v-slot:[`item.accepted`]="{ item }">
					<span style="color: #71a656">
						{{ countAccepted(item.invitations) }}
					</span>
				</template>
				<template v-slot:[`item.declined`]="{ item }">
					<span style="color: #cc564e">
						{{ countDeclined(item.invitations) }}
					</span>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon small class="mr-2 pointer edit" @click.stop="openLinkDialog(item, $event)">
						mdi-content-copy
					</v-icon>
					<v-icon small class="pointer trash" @click.stop="startDeleteEvent(item)">
						mdi-delete
					</v-icon>
				</template>
			</v-data-table>
		</v-container>

		<v-dialog max-width="700" v-model="linkDialog.status">
			<v-card class="w-100" dark>
				<v-card-title class="d-flex align-center justify-space-between">
					<span>Copy invitation link</span>
					<v-icon class="pointer" @click="linkDialog.status = false">
						mdi-close
					</v-icon>
				</v-card-title>
				<v-divider class="w-100"></v-divider>
				<v-card-text v-if="linkDialog.event" class="py-4 flex-center flex-column">
					<span class="w-100 mb-1" style="opacity: 0.6">
						The host for this event has been set to
						<span class="text-uppercase">
							{{ linkDialog.event.host }}
						</span>
					</span>

					<span class="w-100 mb-5 white--text" ref="eventLink" v-if="linkDialog.event.host == 'isbih'">
						https://rsvp.isbih.org/events/{{
		linkDialog.event.slug
	}}/{{ linkDialog.event.id }}
					</span>
					<span class="w-100 mb-5 white--text" ref="eventLink" v-else-if="linkDialog.event.host == 'mta'">
						https://rsvp.mtabraham.com/events/{{
		linkDialog.event.slug
	}}/{{ linkDialog.event.id }}
					</span>

					<v-btn plain class="w-100" depressed tile @click="copyEventLink()" v-if="!copied">
						Copy link to clipboard
					</v-btn>
					<v-icon v-else dark class="mb-2">mdi-check</v-icon>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog max-width="500" v-model="deleteEvent.status">
			<v-card class="w-100" dark>
				<v-card-title class="d-flex align-center justify-space-between">
					<span>Delete event</span>
					<v-icon class="pointer" @click="deleteEvent.status = false">
						mdi-close
					</v-icon>
				</v-card-title>
				<v-divider class="w-100"></v-divider>
				<v-card-text class="py-4 flex-center flex-column">
					<span class="w-100 mb-1 text-left" style="opacity: 0.6">
						Are you sure you want to delete this event? This action
						cannot be reversed.
					</span>
				</v-card-text>
				<v-divider class="w-100"></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text tile depressed @click="deleteEvent.status = false">
						Cancel
					</v-btn>
					<v-btn color="error" text tile depressed @click="confirmDeleteEvent(deleteEvent.event)">
						Confirm
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "HomeView",
	data: () => ({
		menu: false,
		events: [],
		deleteEvent: {
			event: null,
			status: false,
		},
		linkDialog: {
			event: null,
			status: false,
		},
		eventSuccess: null,
		copied: false,
		eventError: null,
		eventsErr: null,
		newEvent: {
			name: null,
			datetime: null,
			host: null,
		},
		hosts: [
			{
				name: "M.T. Abraham Group",
				value: "mta",
			},
			{
				name: "Chamber of Commerce (ISBIH)",
				value: "isbih",
			},
		],
		eventHeaders: [
			{
				text: "Event Name",
				align: "start",
				sortable: false,
				value: "name",
			},
			{
				text: "Date & Time",
				align: "start",
				sortable: true,
				value: "datetime",
			},
			{
				text: "Host",
				align: "start",
				sortable: false,
				value: "host",
			},
			{
				text: "Accepted",
				align: "end",
				sortable: true,
				value: "accepted",
			},
			{
				text: "Declined",
				align: "end",
				sortable: true,
				value: "declined",
			},
			{
				text: "Actions",
				align: "end",
				sortable: false,
				value: "actions",
			},
		],
		items: [
			{title: "Click Me"},
			{title: "Click Me"},
			{title: "Click Me"},
			{title: "Click Me 2"},
		],
	}),
	mounted() {
		this.init()
		this.$store.dispatch("getUser")
	},
	computed: {
		user() {
			return this.$store.getters.currentUser
		},
	},
	methods: {
		init() {
			apimta
				.get(`/events`)
				.then((response) => {
					if(response.status === 200) {
						this.events = response.data
					}
				})
				.catch((err) => {
					this.eventsErr = err
				})
		},
		countAccepted(invitations) {
			return invitations.filter((item) => item.status === 1).length
		},
		countDeclined(invitations) {
			return invitations.filter((item) => item.status === 0).length
		},
		startDeleteEvent(evt) {
			this.deleteEvent.event = evt
			this.deleteEvent.status = true
		},
		copyEventLink() {
			let link = this.$refs.eventLink.innerHTML

			const storage = document.createElement("textarea")
			storage.value = link

			const element = this.$refs.eventLink
			element.appendChild(storage)

			storage.select()
			storage.setSelectionRange(0, 99999)
			document.execCommand("copy")
			element.removeChild(storage)

			this.copied = true
		},
		confirmDeleteEvent(event) {
			apimta
				.delete(`/events/delete/${event.id}`)
				.then((response) => {
					if(response.status === 202) {
						this.deleteEvent.status = false
						this.deleteEvent.event = null
						this.init()
					}
				})
				.catch((err) => {
					this.deleteEvent.status = false
					this.eventError = err
				})
		},
		formatDate(datetime) {
			const date = new Date(datetime)
			return (
				date.toLocaleDateString("en-US", {
					weekday: "long",
					year: "numeric",
					month: "long",
					day: "numeric",
				}) +
				" at " +
				date.toLocaleTimeString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				})
			)
		},
		eventClick(val) {
			this.$router.push(`/events/${val.id}`)
		},
		openLinkDialog(item) {
			this.copied = false
			this.linkDialog.event = item
			this.linkDialog.status = true
		},
		saveEvent() {
			this.eventSuccess = null
			this.eventError = null
			let datestring = this.newEvent.datetime.toString()

			let evt = {
				name: this.newEvent.name,
				datetime: datestring,
				host: this.newEvent.host,
			}

			apimta
				.post(`/events/create`, evt)
				.then((response) => {
					if(response.status === 201) {
						this.eventSuccess = "Event saved successfully."
						this.init()
					}
				})
				.catch((err) => {
					this.eventError = err
				})
		},
	},
}
</script>
<style lang="scss" scoped>
.trash,
.edit {
	opacity: 0.4;
	transition: all 0.5s ease;

	&:hover {
		opacity: 0.8;
	}
}
</style>
