<template>
	<div>
		<div class="w-100 login flex-center flex-column px-5">
			<v-card dark tile max-width="500" class="w-100" style="margin-top: -100px">
				<v-card-title class="w-100">
					<span class="w-100 font-weight-light text-center">
						MTA Events Manager
					</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<form v-on:submit.prevent="login" class="login-container flex-center flex-column pa-5">
						<v-text-field color="#FFFFFF" :rules="inputRule" outlined v-model="email" label="E-mail"
							class="w-100 font-light mb-2"></v-text-field>
						<v-text-field color="#FFFFFF" :rules="inputRule" outlined v-model="password" label="Password"
							class="w-100 font-light mb-2" :type="showPass ? 'text' : 'password'"
							:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showPass = !showPass"></v-text-field>
						<v-btn outlined type="submit" color="#a38e6f" large depressed class="w-100 mt-2">
							<span class="font-weight-light"> Submit </span>
						</v-btn>

						<v-alert type="error" class="mt-5 w-100" v-if="error">
							<span class="white--text">
								{{ error }}
							</span>
						</v-alert>
					</form>
					<p class="w-100 text-center font-light white--text" style="font-size: 10px; opacity: 0.4">
						Having trouble logging in? Contact web administrator.
					</p>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>
<script>
export default {
	name: "LoginView",
	data() {
		return {
			inputRule: [],
			error: null,
			showPass: false,
			email: null,
			password: null,
		}
	},
	computed: {
		user() {
			return this.$store.getters.currentUser
		},
	},
	watch: {
		user(y) {
			if(y) {
				this.$router.push("/")
			}
		},
	},
	mounted() {
		if(this.user.id) {
			this.$router.push("/")
		}
	},
	methods: {
		login: function(e) {
			const data = {
				email: this.email,
				password: this.password,
			}

			apimta
				.post("/login", data)
				.then((response) => {
					if(
						response.status === 200 &&
						response.data.user.admin === 1
					) {
						this.$store.dispatch("getUser")
						this.$cookies.set("mta-token", response.data.token)
						this.$router.replace("/")
					} else {
						this.error = "Incorrect credentials."
					}
				})
				.catch(() => {
					this.inputRule = [
						(val) =>
							(val || "").length > 0 || "This field is required",
					]
					this.error = "Incorrect credentials."
				})
		},
	},
}
</script>
<style lang="scss">
.login {
	padding-top: 120px;
}
</style>
