import Vue from "vue"
import Vuex from "vuex"
import router from "./router"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: {},
	},
	getters: {
		currentUser: (state) => {
			return state.user
		},
	},
	mutations: {
		getUser(state) {
			apimta
				.get("/user")
				.then((response) => {
					if (response.status === 200) {
						state.user = response.data
					}
				})
				.catch((err) => {
					router.push("/login")
				})
		},
	},
	actions: {
		getUser(context) {
			context.commit("getUser")
		},
	},
})
